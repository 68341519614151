import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { PWAAssets } from "~/components/PWAAssets";
import "@radix-ui/themes/styles.css";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/index.js";
import { Header } from "~/components/Header";
import React from "react";
import { Theme } from "@radix-ui/themes";
import { LinksFunction } from "@remix-run/node";
import styles from "./index.css?url";
export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const graphQLClient = new ApolloClient({
  ssrMode: true,
  link: createHttpLink({
    uri: "https://spise-api.egge.cloud/graphql",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  }),
  cache: new InMemoryCache(),
});

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const isRootLocation = location.pathname === "/";
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <PWAAssets />
        <Links />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@400;700&display=swap"
        />
      </head>
      <body style={{ margin: 0 }}>
        <ApolloProvider client={graphQLClient}>
          <Theme accentColor="orange" radius="large">
            {!isRootLocation && <Header />}
            {children}
          </Theme>
        </ApolloProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Ooops, noe gikk galt</div>;
};

export default function App() {
  return <Outlet />;
}

export function HydrateFallback() {
  return <p>Hydrerer...</p>;
}
