import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { ClientOnly } from "remix-utils/client-only";
import { FC, useState } from "react";
import { Box, Button, Flex, Text } from "@radix-ui/themes";
import { LockClosedIcon, LockOpen1Icon, PlusIcon } from "@radix-ui/react-icons";

const WakeLockButton: FC = () => {
  const enabled = false; // wakeLockSupported();
  const [isWakeLockActive, setIsWakeLockActive] = useState(false);
  const [sentinel, setSentinel] = useState<WakeLockSentinel | null>(null);

  const toggleWakeLock = async () => {
    if (isWakeLockActive) {
      if (sentinel) {
        await sentinel.release();
        setSentinel(null);
        setIsWakeLockActive(false);
      }
    } else {
      const newSentinel = null; // await wakeLock();
      if (newSentinel) {
        setSentinel(newSentinel);
        setIsWakeLockActive(true);
      }
    }
  };

  return (
    <Button size="3" onClick={toggleWakeLock} disabled={!enabled}>
      {isWakeLockActive ? (
        <LockClosedIcon height="20" width="20" />
      ) : (
        <LockOpen1Icon height="20" width="20" />
      )}
    </Button>
  );
};

export const Header: FC = () => {
  return (
    <Box
      as="header"
      position="sticky"
      top="0"
      left="0"
      style={{ zIndex: 1000, background: "var(--color-background)" }}
    >
      <Flex
        direction="row"
        justify="between"
        align="center"
        pt="2"
        pb="2"
        ml="2"
        mr="2"
      >
        <Link
          to={$path("/")}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Text size="6">Spise</Text>
        </Link>
        <Link
          to={$path("/oppskrifter")}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Text size="6">Oppskrifter</Text>
        </Link>
        <>
          <Link
            to={$path("/oppskrifter/ny")}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <PlusIcon />
          </Link>
          <ClientOnly>{() => <WakeLockButton />}</ClientOnly>
        </>
      </Flex>
    </Box>
  );
};
